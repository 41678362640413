import React, {forwardRef} from "react";

import {FieldWrapper, IFieldWrapperProps} from "../../../atoms/wrapper/FieldWrapper";
import {getFieldState} from "../../../field_state_helper";
import {getErrorMessage} from "../../../utils/get_error_message";
import {ITimeInputProps, TimeInput} from "./TimeInput";

export type IDateFieldProps = IFieldWrapperProps &
    Omit<ITimeInputProps, "type"> & {
        error?: string | string[];
    };

export const TimeField = forwardRef<HTMLInputElement, IDateFieldProps>((props, inputForwardedRef) => {
    const {labelContent, message, id, className, onChange, error, ...inputProps} = props;

    const fieldState = getFieldState(props);
    const errorMessage = getErrorMessage(error);

    return (
        <FieldWrapper labelContent={labelContent} fieldState={fieldState} message={errorMessage || message} htmlFor={id} className={className}>
            <TimeInput {...inputProps} inputForwardedRef={inputForwardedRef} id={id} fieldState={fieldState} onChange={onChange} />
        </FieldWrapper>
    );
});
